import { Col, Row } from "antd";
import numeral from "numeral";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getRecibo, Recibo } from "../../../services/";

import styled from "styled-components";
import { useParams } from "react-router-dom";
import moment from "moment";
import { PrevidenciaristaLogo, PrevLogo, Table, Text, usePrevTheme } from "@prev/ui-kit";

const Label = styled.div`
  margin: 0 0 12px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.8px;
`;

const Item = styled.div`
  margin: 0 0 49px;
`;

const IconContrato = styled.div`
  margin: 0 0 17px;
`;

const ListItem = styled.div`
  margin: 0 0 9px;
  cursor: pointer;
  i {
    margin: 0 9px 0;
    color: #989898;
  }
`;

const Menu = styled.div`
  padding: 24px 0 0 43px;
`;

const HelpInfo = styled.div`
  background-color: #f8f8f8;
  height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.48);
`;

const Container = styled.div`
  padding: 16px;
  max-width: 1024px;
  font-size: 16px;

  td { 
      padding: 8px;
      font-size: 16px;
  }

  @media print {
    td {
        font-size: 12px;
    }
}
`

const FinanceiroRecibo = () => {
    const [dados, setDados] = useState<Recibo | undefined>()
    const { id } = useParams();
    const { theme } = usePrevTheme()

    useEffect(() => {
        void handleRecibo()
    }, []);

    // Colunas da tabela
    const columns = [
        {
            title: "Data do pagamento",
            dataIndex: "dataDoPagamento",
            key: "dataDoPagamento",
            render: (text: string) => {
                return (
                    <Text style={{ color: theme.color.text.subtlest }}>{moment(text).format("DD/MM/YYYY")}</Text>
                )
            },
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            key: "descricao",
            render: (text: string) => (
                <Text style={{ color: theme.color.text.subtlest }}>{text}</Text>
            )
        },
        {
            title: "Período de serviço",
            dataIndex: "periodo",
            key: "periodo",
            render: (periodo: { inicio: string; fim: string }) => (
                <Text style={{ color: theme.color.text.subtlest }}>
                    {moment(periodo.inicio).format("DD/MM/YYYY")} até {moment(periodo.fim).format("DD/MM/YYYY")}
                </Text>
            )
        },
        {
            title: "Valor",
            dataIndex: "valorBruto",
            key: "valorBruto",
            render: (valor: number) => (
                <Text style={{ color: theme.color.text.subtlest }}>
                    R$ {numeral(valor).format("0,0.00")}
                </Text>
            ),
        },
        {
            title: "Descontos",
            dataIndex: "valorDoDesconto",
            key: "valorDoDesconto",
            render: (valor: number) => (
                <Text style={{ color: theme.color.text.subtlest }}>
                    R$ {numeral(valor).format("0,0.00")}
                </Text>
            ),
        },
        {
            title: "Subtotal",
            dataIndex: "valorTotal",
            key: "valorTotal",
            render: (valor: number) => (
                <Text style={{ color: theme.color.text.subtlest }}>
                    R$ {numeral(valor).format("0,0.00")}
                </Text>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            dataDoPagamento: dados?.dataDoPagamento || "-",
            descricao: "Assinatura do Previdenciarista",
            periodo: {
                inicio: dados?.periodo.inicio || "-",
                fim: dados?.periodo.fim || "-",
            },
            valorBruto: dados?.valorBruto || '-',
            valorDoDesconto: dados?.valorDoDesconto || '-',
            valorTotal: dados?.valorTotal || '-',
        },
    ];

    const handleRecibo = async () => {
        if (id) {
            const recibo = await getRecibo(id)
            setDados(recibo)
        }
    }

    return dados ?
        <Container>
            <PrevidenciaristaLogo />
            <Row style={{ marginTop: "24px" }}>
                <Text style={{ color: theme.color.text.subtlest }}>{dados.prestador.razaoSocial}</Text>
            </Row>
            <Row>
                <Text style={{ color: theme.color.text.subtlest }}>
                    Rua {dados.prestador.endereco.logradouro}, {dados.prestador.endereco.numero},{" "}
                    {dados.prestador.endereco.complemento}, {dados.prestador.endereco.bairro},{" "}
                    {dados.prestador.endereco.cidade}/{dados.prestador.endereco.uf}, CEP {dados.prestador.endereco.cep}.
                </Text>
            </Row>
            <Row>
                <Text style={{ color: theme.color.text.subtlest }}>CNPJ: {dados.prestador.cnpj}</Text>
            </Row>
            <Row style={{ marginTop: "24px" }}>
                <Text emphasis style={{ color: theme.color.text.subtlest }}>{dados.tomador.escritorioNome}</Text>
            </Row>
            <Row>
                <Text style={{ color: theme.color.text.subtlest }}>CNPJ {dados.tomador.escritorioCnpj}</Text>
            </Row>
            <Row>
                <Text style={{ color: theme.color.text.subtlest }}>{dados.tomador.nome}</Text>
            </Row>
            <Row>
                <Text style={{ color: theme.color.text.subtlest }}>CPF {dados.tomador.numeroDoDocumento}</Text>
            </Row>
            <Row>
                <Text style={{ color: theme.color.text.subtlest }}>{dados.tomador.email}</Text>
            </Row>
            <Row style={{ marginTop: "24px" }}>
                <Text style={{ color: theme.color.text.subtlest }}>Nr. da fatura: {dados.uuidDaFatura}</Text>
            </Row>
            <Table
                style={{ marginTop: "24px" }}
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                summary={() => (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={6}>
                                <Text style={{ textAlign: "left", fontSize: "14px", color: theme.color.text.subtlest, marginTop: "32px", fontStyle: "italic" }}>
                                    Assinatura do Previdenciarista CONFORME LEI 12.741/2012 o valor aproximado dos tributos é R$ 948,80 (15,45%), FONTE: IBPT/empresometro.com.br(19.2.B). Empresa dispensada de emissão de nota fiscal de serviço por tomador e autorizada a emitir notas fiscais pelo regime especial de estimativa conforme Parecer 285/2020/ISS/SMF emitido no Processo 2020/08/25808 pela Secretaria Municipal de Fazenda de Santa Maria/RS.
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={1} colSpan={5} >
                                <Text style={{ textAlign: "right", fontWeight: "bold", borderLeft: "none" }}>TOTAL</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <Text emphasis>
                                    {`R$ ${numeral(dados?.valorTotal || 6141.12).format("0,0.00")}`}
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                )}

            />
        </Container> : null

}

FinanceiroRecibo.contextTypes = {
    analytics: PropTypes.object.isRequired
};

export default FinanceiroRecibo;
