import { type AxiosResponse, authGetWithHeader, authPost, authGet, authPut, authDelete, PrevAxios, getToken } from '@prev-front/shared-api'
import QueryString from 'qs'

import { API_HOST_DASHBOARD_V2, API_HOST_V3 } from "./constants"

interface GetClientsProps {
  limite: number
  query?: string
  ordenaPor: string
  pagina: number
  token: string
}

export const getClients = async (props: GetClientsProps): Promise<any> => {
  const { limite, ordenaPor, pagina, query } = props
  const response = await authGetWithHeader(
    `/clientes?limite=${limite}&ordenaPor=${ordenaPor}&pagina=${pagina}&q=${query}`, API_HOST_DASHBOARD_V2
  )
  return response as AxiosResponse
}

export interface Profissao {
  codigo: string
  titulo: string
  condicoesGeraisDoExercicio: string
  descricaoSumaria: string
}

export interface NewClient {
  nome: string
  cpf: string
  estadoCivil: 'SOLTEIRO' | 'CASADO' | 'VIUVO' | 'DIVORCIADO' | 'SEPARADO'
  sexo: 'MASCULINO' | 'FEMININO'
  dataDeNascimento: string
  fichaDeAtendimento?: AttendanceSheet
  profissoes?: Profissao[]
  email?: string
  telefonePrincipal?: string
  celular?: string
  telefoneSecundario?: string
  endereco?: Endereco
  escolaridade?: 'ENSINO_FUNDAMENTAL_INCOMPLETO' | 'ENSINO_FUNDAMENTAL_COMPLETO' | 'ENSINO_MEDIO_INCOMPLETO' | 'ENSINO_MEDIO_COMPLETO' | 'ENSINO_SUPERIOR_INCOMPLETO' | 'ENSINO_SUPERIOR_COMPLETO' // Adicione ou remova conforme necessário
  ctps?: string
  nit?: string
  dataDeObito?: string
  rg?: string
  cnh?: string
  nitSecundario?: string
}

export interface AttendanceSheet {
  id: number
  uuid: string
  nasceuZonaRuralEconomiaFamiliar: boolean
  totalDeHectares: number
  totalDeEmpregados: number
  serviuExercito: boolean
  fezEscolaTecnica: boolean
  foiJovemAprendiz: boolean
  exerceuServicoPublicoConcursado: boolean
  teveCertidaoDeTempoDeContribuicao: boolean
  teveContratoDeExperiencia: boolean
  teveRiscoDeSaudeOuIntegridadeFisica: boolean
  descricaoRiscoDeSaudeOuIntegridadeFisica: string
  algumColegaDeTrabalhoReconheceuAtividadeEspecial: boolean
  foiAutonomoOuContribuinteIndividual: boolean
  teveProcessoJudicialTrabalhista: boolean
  teveProcessoJudicialPrevidenciario: boolean
  recebeuOuPediuBeneficio: boolean
  temCopiaDoProcessoAdministrativo: boolean
  trabalhouForaDoBrasil: boolean
  recolheuGPS: boolean
  trabalhouComoPessoaComDeficiencia: boolean
  sofreOuSofreuDoencaQueDificultaTrabalhar: boolean
  responsaveis: string[]
}

export interface ClientData extends NewClient {
  id: number
  uuid: string
  dataDeAtualizacao: string
}

export const getClientData = async (cpf: string): Promise<ClientData> => {
  const data = await authGet(`/clientes/${cpf}`, API_HOST_DASHBOARD_V2)
  return data as ClientData
}

export const createClient = async (payload: NewClient): Promise<any> => {
  console.log(payload)
  const response = await authPost({
    path: '/clientes',
    data: {
      ...payload
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetHistoryProps {
  cpf: string
  limite: number
  pagina: number
}

interface CriadoPor {
  id: string
}

export interface HistoryEvent {
  id: string
  clienteId: string
  tipo: string
  categoria: string
  mensagem: string
  criadoPor: CriadoPor
  destinadoPara: Record<string, unknown>
  dataDeCriacao: string
  dataDaUltimaAtualizacao: string
  respostas: any[]
}

export interface PaginatedHistResponse {
  total: number
  totalDePaginas: number
  proximo: number
  eventos: HistoryEvent[]
}

export const getHistory = async (props: GetHistoryProps): Promise<PaginatedHistResponse> => {
  const { cpf, limite, pagina } = props
  const response = await authGet(
    `/clientes/${cpf}/historico?limite=${limite}&pagina=${pagina}`, API_HOST_DASHBOARD_V2
  )
  return response as PaginatedHistResponse
}

interface PostHistoryMessageProps {
  cpf: string
  mensagem: string
}

export const postHistoryMessage = async (props: PostHistoryMessageProps): Promise<HistoryEvent> => {
  const { cpf, mensagem } = props
  const response = await authPost({
    path: `/clientes/${cpf}/historico`,
    data: {
      mensagem
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response as HistoryEvent
}

interface updateClientProps {
  cpf: string
  payload: any
}

export const updateClient = async (props: updateClientProps): Promise<any> => {
  const { cpf, payload } = props
  console.log("payload", payload)
  const response = await authPut({
    path: `/clientes/${cpf}`,
    data: {
      ...payload
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetCalcsProps {
  clientId: string
  limit: number
  page: number
}

export interface GetCalcsResponse {
  id: number
  uuid: string
  tipo: string
  nome: string
  dataDeCriacao: string
  carencia: number
  dataDeInscricao: string
}

export const getCalcs = async (props: GetCalcsProps): Promise<any> => {
  const { clientId, limit, page } = props
  const path = `/calculos/cliente/${clientId}?limite=${limit}&pagina=${page}`
  const response = await authGetWithHeader(path, API_HOST_V3)
  return {
    headers: response.headers,
    data: response.data
  }
}

export const deleteCalc = async (calcId: number): Promise<any> => {
  const response = await authDelete({
    path: `/calculos/${calcId}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetClientPetitonsProps {
  cpf: string
}

export interface GetCLientPetitionsResponse {
  id: string
  idDoModeloDaPeticao: string
  idDoCliente: string
  cpfDoCliente: string
  idDoEscritorio: number
  titulo: string
  resumo: string
  tipo: string
  tipoDeAcao: string
  tags: string[]
  criadoPor: string
  atualizadoPor: string
  dataDeCriacao: string
  dataDaUltimaAtualizacao: string
  sexo: 'MASCULINO' | 'FEMININO' // Assumindo que são as únicas opções
  tipoDeProcesso: string
  tiposDeBeneficio: string[]
  conteudo: string
}

export interface IAGetPetition {
  peticoes: GetCLientPetitionsResponse[]
}

export const getClientPetitions = async (props: GetClientPetitonsProps): Promise<IAGetPetition> => {
  const path = `/search/peticoes?cpf=${props.cpf}`
  const response = await authGet(path, API_HOST_V3)
  return response
}

export const deleteClientPetition = async (petitionId: string): Promise<any> => {
  const response = await authDelete({
    path: `/clientes-peticoes/${petitionId}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetClientUploadsProps {
  cpf: string
}

export interface GetClientUploadsResponse {
  id: string
  clienteId: string
  nome: string
  nomeAws: string
  tipoArquivoDocumento: string
  tipoArquivoDocumentoDescricao: string
  url: string
  dataDeCriacao: string
  dataDeAtualizacao: string
}

export const getClientUploads = async (props: GetClientUploadsProps): Promise<GetClientUploadsResponse[]> => {
  const path = `/clientes-arquivos/${props.cpf}`
  const response = await authGet(path, API_HOST_DASHBOARD_V2)
  return response as GetClientUploadsResponse []
}

export const deleteClientUpload = async (uploadId: string): Promise<any> => {
  const response = await authDelete({
    path: `/clientes-arquivos/${uploadId}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

export const deleteClient = async (cpf: string): Promise<any> => {
  const response = await authDelete({
    path: `/clientes/${cpf}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

export const downloadClientUpload = async (uploadId: string): Promise<Blob> => {
  const token = getToken()
  const response = await PrevAxios.get(`${API_HOST_DASHBOARD_V2}/clientes-arquivos/${uploadId}/download`, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data as Blob
}

interface GetClientContractProps {
  cpf: string
  tipo: string
}

interface GetClientContractResponse {
  content: string
}

export const getClientContract = async (params: GetClientContractProps): Promise<GetClientContractResponse> => {
  const path = `/clientes/${params.cpf}/contratos/tipos/${params.tipo}`
  const response = await authGet(path, API_HOST_DASHBOARD_V2)
  return response as GetClientContractResponse
}

interface PostClientUploadProps {
  cpf: string
  payload: {
    tipoDocumento: string
    arquivo: File
    onUploadProgress?: (progressEvent: any) => void
  }
}
export const postClientUpload = async (props: PostClientUploadProps): Promise<any> => {
  const { cpf, payload } = props
  const form = new FormData()
  const token = getToken()
  form.append('tipoDocumento', payload.tipoDocumento)
  form.append('arquivo', payload.arquivo)

  await PrevAxios.post(`${API_HOST_DASHBOARD_V2}/clientes-arquivos/${cpf}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    onUploadProgress (progressEvent) {
      if (payload.onUploadProgress) {
        payload.onUploadProgress(progressEvent)
      }
    }
  })
}

export const getEnderecoByCep = async (cep): Promise<any> => {
  return await authGet(`/utils/cep/${cep}`)
}

type Params = Record<string, any>

export interface JobsInterface {
  codigo: string
  titulo: string
  sinonimos: string[]
  condicoesGeraisDoExercicio: string
  descricaoSumaria: string
}

export async function getJobs (params: Params): Promise<JobsInterface[] | null> {
  try {
    const queryString = QueryString.stringify(params, { addQueryPrefix: true, skipNulls: true })
    const response = await authGet(`/profissoes${queryString}`, API_HOST_DASHBOARD_V2)
    if (response) {
      return response as JobsInterface[]
    } else {
      throw new Error('Resposta vazia do servidor.')
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { getCookie, get, post } from '@prev-front/shared-api'
import { API_HOST_V1, REACT_APP_MAPBOX_ACCESS_TOKEN } from "./constants"
import { type OfficeInfoResponse, type CasosInterface, type UserInfoResponse } from './types'

import qs from 'qs'


export enum GroupByOptions {
  'DAY' = 'DIA',
  'WEEK' = 'SEMANA',
  'MONTH' = 'MES',
  'YEAR' = 'ANO',
}

export interface getDashboardReportsProps {
  type: string
  subType: string
  from?: string
  to?: string
  groupBy?: GroupByOptions
}

export const getDashboardReports = async (props: getDashboardReportsProps): Promise<DashboardReportsData[]> => {
  const { type, subType } = props
  let requestString = `/relatorios/${type}/criacao-${subType}?de=${props.from}&ate=${props.to}&agrupadoPor=${props.groupBy}`
  if (subType === 'peticoes') {
    requestString = `/search/peticoes/relatorio-peticoes?de=${props.from}&ate=${props.to}&agrupadoPor=${props.groupBy}`
  }
  const data = await authGet(requestString, subType === 'peticoes' ? API_HOST_V3 : API_HOST_DASHBOARD_V2)
  return data
}

export interface DashboardReportsData {
  quantidade: number
  data: string
}

interface GetCasosProps {
  limite: number
  ordenarPor: string
  pagina?: number
  reservado?: boolean
}

interface GetCasosResponse {
  data: CasosInterface[]
  headers: any
}

export const getCasos = async (props: GetCasosProps): Promise<GetCasosResponse> => {
  const { limite, ordenarPor, pagina } = props
  const userJson = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const data = await PrevAxios({
    method: 'GET',
    url: `${API_HOST_DASHBOARD_V2}/casos?limite=${limite}&ordenarPor=${ordenarPor}&pagina=${pagina}&reservado=${props.reservado}`,
    headers: {
      Authorization: `Bearer ${userJson}`,
      ...prev_session
    }
  })
  return {
    data: data?.data ?? [] as CasosInterface[],
    headers: data?.headers
  }
}

export const getCasoFull = async (id: string): Promise<CasosInterface> => {
  const userJson = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const data = await PrevAxios({
    method: 'GET',
    url: `${API_HOST_DASHBOARD_V2}/casos/${id}`,
    headers: {
      Authorization: `Bearer ${userJson}`,
      ...prev_session
    }
  })
  return data.data as CasosInterface
}

interface ReserveCaseInterface {
  id: string
  description: string
}

export const reserveCase = async (props: ReserveCaseInterface): Promise<any> => {
  const { id, description } = props
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const token = getToken()
  const response = await PrevAxios({
    method: 'POST',
    url: `${API_HOST_DASHBOARD_V2}/casos/${id}/reservas`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/html',
      ...prev_session
    },
    data: description
  })
  return response as any
}

interface getPetitionsParams {
  params?: any
}

export const getPetitions = async ({
  params = 'ordenaPor=DATA_DE_ATUALIZACAO&size=6&tipoDeOrdenacao=DESC'
}: getPetitionsParams): Promise<any> => {
  return await authGetWithHeader(`/search/templates/app-search?${params}`, API_HOST_V3)
}

export const getPetitionFull = async ({
  id
}: {
  id: string
}): Promise<any> => {
  return await authGetWithHeader(`/search/templates/${id}`, API_HOST_V3)
}

const { API_AUTH } = process.env

export const getSlug = async (slug: string): Promise<any> => {
  const data = await get(`/oauth/escritorios/${slug}`)
  return data
}

interface ForgotOfficeData {
  username: string
}

export const forgotOffice = async (data: ForgotOfficeData): Promise<any> => {
  const response = await post('/oauth/escritorio/recupera', data)
  return response
}

interface LoginData {
  grant_type: string
  slug: string
  username: string
  password: string
}

export const login = async (data: LoginData): Promise<any> => {
  const authApp = `Basic ${API_AUTH}`

  const payload = {
    grant_type: data.grant_type || 'password',
    slug: data.slug,
    username: data.username,
    password: data.password
  }

  const response = await post('/oauth/token', qs.stringify(payload), {
    headers: {
      Authorization: authApp,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  return response
}


export const updateOnboarding = async (values): Promise<void> => {
  await authPost({
    path: '/escritorios',
    data: values
  })
}

export const deleteSubscription = async (): Promise<any> => {
  const response = await authDelete({
    path: '/assinaturas'
  })
  return response
}

export interface PricingParams {
  intervalo: string
  tipoDeEvento?: string
  tipoDeMeioDePagamento?: string
  tiposDeModulo?: string[]
}

interface Assinatura {
  intervalo: string
  dataDaProximaCobranca: string
  periodo: {
    inicio: string
    fim: string
    dataDaCobranca: string
  }
  tiposDeModulo: string[]
}

interface PrecosModulos {
  tipo: string
  valorBase: number
  porcentagemDesconto: number
  valorDesconto: number
  valorLiquido: number
  quantidadeMaximoUsos: number
  quantidadeMinimaUsos: number
}

interface Parcela {
  parcelas: number
  jurosPercentual: number
  valorTotal: number
  jurosValor: number
  valorDaParcela: number
}

interface TabelaDePrecoModulos {
  precosModulos: PrecosModulos[]
}


export const getPricing = async (params: PricingParams): Promise<PricingResponse> => {
  const reponse = await put({
    data: params,
    path: '/pricing',
    host: API_HOST_DASHBOARD_V2
  })

  return reponse as PricingResponse
}

interface Transacao {
  uuid: string
  codigoDeAutorizacao: string
  valor: number
  status: string
  tipo: string
  meioDePagamento: string
  mensagem: string
  codigoDeRespostaDoGateway: string
  mensagemDeRespostaDoGateway: string
  dataDeCriacao: string
}

interface Modulo {
  tipo: string
  valorBase: number
  valorDoDesconto: number
  valor: number
}

interface Periodo {
  id: number
  uuid: string
  inicio: string
  fim: string
  dataDaCobranca: string
  motivo: string
  tiposDeModulo: string[]
}

export interface Fatura {
  uuid: string
  status: string
  valorBase: number
  valorDoDesconto: number
  valor: number
  dataDoVencimento: string
  dataDoPagamento: string
  dataDeCriacao: string
  dataDeAtualizacao: string
  numeroDeParcelas: number
  possuiRecibo: boolean
  periodo: Periodo
  modulos: Modulo[]
  transacoes: Transacao[]
}

export const getFaturas = async (): Promise<Fatura[]> => {
  const response = await authGet('/assinatura/faturas', API_HOST_DASHBOARD_V2)
  return response
}

interface Usuario {
  id?: number
  uuid?: string
  nome?: string
  email?: string
  cpf?: string
}

interface Endereco {
  id?: number
  uf?: string
  cep?: string
  endereco?: string
  bairro?: string
  cidade?: string
  numero?: string
  complemento?: string
}

export interface UserObject {
  id?: number
  usuario?: Usuario
  nome?: string
  email?: string
  cpf?: string
  profissao?: string
  sexo?: string
  telefone?: string
  celular?: string
  dataDeNascimento?: string
  idade?: number
  endereco?: Endereco
  numeroDaOAB?: string
  privilegio?: string
  status?: string
  dataDoUltimoAcesso?: string
  urlImagemPerfil?: string
}

export const getAccountInfo = async (): Promise<UserObject> => {
  const data = await authGet('/contas/info')
  return data
}

interface Boleto {
  codigoDeBarras: string
  dataDeVencimento: string
  urlDeImpressao: string
}

export interface PaymentInfo {
  uuid: string
  uuidExterno: string
  valor: number
  status: string
  origemDoPagamento: string
  dataDeCriacao: string
  dataDeAtualizacao: string
  meioDePagamento: string
  boleto: Boleto
}

export const getPagamentos = async (): Promise<PaymentInfo[]> => {
  const data = await authGet('/pagamentos', API_HOST_DASHBOARD_V2)
  return data
}

interface PassChangeInterface {
  senha_atual: string
  nova_senha: string
}

export const changePassword = async (params: PassChangeInterface): Promise<void> => {
  await authPut({
    data: qs.stringify({
      senha_atual: params.senha_atual,
      senha_nova: params.nova_senha
    }),
    path: '/contas/senha'
  })
}

export const changeAccountInfo = async (payload: UserProfile): Promise<void> => {
  await authPut({
    data: payload,
    path: '/contas/info'
  })
}

export interface ChangeCardInterface {
  cvv: string
  nome: string
  numero: string
  bandeira: string
  dataDeVencimento: string
}

export const changeCreditCard = async (payload: ChangeCardInterface): Promise<void> => {
  await authPut({
    data: payload,
    path: '/assinaturas/info/cartaoDeCredito'
  })
}

export const getOfficeInfo = async (): Promise<OfficeInfoResponse> => {
  const response = await authGet('/escritorios/info')
  return response
}

export const patchSlug = async (slug: string): Promise<void> => {
  const token = getToken()
  await PrevAxios.patch(`${API_HOST_V1}/escritorios/info`, { slug }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export const patchImageOffice = async (file: any): Promise<void> => {
  const token = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  await PrevAxios({
    method: 'PATCH',
    data: file,
    url: `${API_HOST_V1}/escritorios/imagem-escritorio`,
    headers: {
      Authorization: `Bearer ${token}`,
      ...prev_session
    }
  })
}

export const patchUsuarioImage = async (file: any): Promise<void> => {
  const token = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  await PrevAxios({
    method: 'PATCH',
    data: file,
    url: `${API_HOST_V1}/contas/imagem-perfil`,
    headers: {
      Authorization: `Bearer ${token}`,
      ...prev_session
    }
  })
}

export const removeOfficeImage = async (type: string): Promise<void> => {
  await authDelete({
    path: `/escritorios/imagem-escritorio/${type}`
  })
}

export const putOfficeInfo = async (payload: OfficeInfoResponse): Promise<void> => {
  await authPut({
    data: payload,
    path: '/escritorios/info'
  })
}

export const cleanObject = (obj: any): any => {
  for (const key in obj) {
    // Adiciona a condição para verificar strings vazias
    if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
      delete obj[key]
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item: any) => {
        if (typeof item === 'object') {
          cleanObject(item)
          return Object.keys(item).length > 0
        }
        return item !== undefined && item !== null && item !== ''
      })
      if (obj[key].length === 0) {
        delete obj[key]
      }
    } else if (typeof obj[key] === 'object') {
      cleanObject(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
  }
  return obj
}

export const validateCNPJ = (cnpj): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj === '') return false
  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (['00000000000000', '11111111111111', '22222222222222', '33333333333333',
    '44444444444444', '55555555555555', '66666666666666', '77777777777777',
    '88888888888888', '99999999999999'].includes(cnpj)) return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(0))) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(1))) return false

  return true
}

export const findCoordinatesByZip = async (zipcode: string): Promise<any> => {
  const data = await PrevAxios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/&types=postcode&country=BR&postcode=${zipcode}.json?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`)
  return data
}

export const getCourse = async (id: string): Promise<any> => {
  const response = await authGet(`/cursos/${id}`, API_HOST_DASHBOARD_V2)
  return response
}

export const getModules = async (id: string): Promise<any> => {
  const response = await authGet(`/cursos/${id}/modulos`, API_HOST_DASHBOARD_V2)
  return response
}

interface Curso {
  id: number
}

interface Modulo {
  id: number
  curso: Curso
}

export interface Aula {
  id: number
  modulo: Modulo
  uuid: string
  titulo: string
  descricao: string
  videoUrl: string
  posicao: number
}

export const getLessons = async (id: string): Promise<Aula[]> => {
  const response = await authGet(`/cursos/modulos/${id}/aulas`, API_HOST_DASHBOARD_V2)
  return response
}

const YT_URL = 'https://youtube.googleapis.com/youtube/v3'

export const fetchLastVideo = async (): Promise<any> => {
  try {
    const url = `${YT_URL}/activities`
    const { data } = await PrevAxios.get(url, {
      params: {
        part: 'snippet,contentDetails',
        channelId: 'UCn9YzkwOh82M9JChf1pWu5g',
        maxResults: 1,
        order: 'date',
        key: process.env.REACT_APP_YOUTUBE_KEY
      }
    })
    return data.items[0]
  } catch (e) {
    // return  {
    //   "kind": "youtube#activity",
    //   "etag": "FrbDuxEYA-WOohktQA4-wel7t9o",
    //   "id": "MTUxNjE2NTMzMjIyMTYxNjUzMzIyMjAwMDg4NQ",
    //   "snippet": {
    //       "publishedAt": "2021-03-23T21:00:22+00:00",
    //       "channelId": "UCn9YzkwOh82M9JChf1pWu5g",
    //       "title": "TEMA 709: Quais as condições para ter os efeitos suspensos sendo profissional da saúde?",
    //       "thumbnails": {
    //           "default": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/default.jpg",
    //               "width": 120,
    //               "height": 90
    //           },
    //           "medium": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/mqdefault.jpg",
    //               "width": 320,
    //               "height": 180
    //           },
    //           "high": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/hqdefault.jpg",
    //               "width": 480,
    //               "height": 360
    //           },
    //           "standard": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/sddefault.jpg",
    //               "width": 640,
    //               "height": 480
    //           },
    //           "maxres": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/maxresdefault.jpg",
    //               "width": 1280,
    //               "height": 720
    //           }
    //       },
    //       "channelTitle": "Previdenciarista",
    //       "type": "upload"
    //   },
    //   "contentDetails": {
    //       "upload": {
    //           "videoId": "xR9X-Gl87jg"
    //       }
    //   }
    // }
  }
}

const WP_URL =
  'https://dnz6yh8i3gcjs.cloudfront.net/blog/wp-json/wp/v2/posts'

const handleResponse = async (response: any = [], key): Promise<any> => {
  const handled = await Promise.all(
    response.map((item) => {
      //   let imgSrc = null;

      //   if (key !== "decisoes") {
      //     if (
      //       item._links["wp:featuredmedia"] &&
      //       item._links["wp:featuredmedia"].length > 0
      //     ) {
      //       let mediaUrl = item._links["wp:featuredmedia"][0].href;
      //       mediaUrl = mediaUrl.replace(
      //         "https://previdenciarista.com",
      //         "https://cdn.previdenciarista.com"
      //       );
      //       imgSrc = await getThumbnail(mediaUrl);
      //     }
      //   }

      return {
        id: item.id,
        date: item.modified_gmt,
        link: `https://previdenciarista.com/blog/${item.slug}`,
        // link: item.link,
        title: item.title.rendered,
        imgSrc:
          item._embedded?.['wp:featuredmedia']?.['0'].source_url,
        author: item._embedded?.author[0].name,
        category: item._embedded?.['wp:term'][0][0].name
      }
    })
  )
  return handled
}

const getByCategory = async (category, key): Promise<any> => {
  const queryString = qs.stringify(
    {
      per_page: 1,
      categories: category,
      _embed: true
    },
    { addQueryPrefix: true, skipNulls: true, indices: false }
  )

  try {
    const res = await PrevAxios.get(`${WP_URL}${queryString}`)
    return res.status === 200 ? await handleResponse(res.data, key) : []
  } catch (e) {
    return []
  }
}

export const fetchLastBlogPost = async (): Promise<any> => {
  const [noticia] = await fetchBlogPosts()
  return noticia
}

export const fetchBlogPosts = async (): Promise<any> => {
  return await getByCategory('50,18', 'blog')
}

export const fetchNews = async (): Promise<any> => {
  return await getByCategory(18, 'noticias')
}

export const fetchDecisoes = async (): Promise<any> => {
  return await getByCategory(123, 'decisoes')
}

export const fetchColunas = async (): Promise<any> => {
  return await getByCategory(2, 'colunas')
}

export const getAulaHtml = async ({ moduloId, aulaId }): Promise<any> => {
  return await authGet(`/cursos/modulos/${moduloId}/aulas/${aulaId}/html`)
}

interface PostMemberInterface {
  email: string
  nome: string
  privilegio: string
}

export const postMember = async (payload: PostMemberInterface): Promise<any> => {
  await authPost({
    data: payload,
    path: '/membros'
  })
}


export const deleteMember = async (id: string): Promise<void> => {
  await authDelete({
    path: `/membros/${id}`
  })
}

/* eslint-disable @typescript-eslint/naming-convention */
import { CheckoutRequest, CheckoutResponse, Info, PricingRequest, PricingResponse, StatusPixResponse, put } from '@prev-front/shared-api'
import { UserProfile } from '../components/userAccontDrawer/UserAccountDrawer'
import styled from 'styled-components'

interface ForgotOfficeData {
  username: string
}

interface LoginData {
  grant_type: string
  slug: string
  username: string
  password: string
}

export interface IOffice {
  id: number
  nome: string
  slug: string
  cnpj: string
}

export interface IToken {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}

interface NewMemberProps {
  senha_nova: string
  token: string
}

export interface ResetPropsPasswordProps {
  senha_nova: string
  token: string
}

export const recoveryPassword = async (params: { slug: string, username: string }): Promise<void> => {
  await authPost({
    data: params,
    path: '/oauth/senha/recupera',
    host: API_HOST_V1
  })
}



export const resetPassword = async (params: ResetPropsPasswordProps): Promise<void> => {
  const form = {
    senha_nova: params.senha_nova,
    token: params.token
  }
  const formData = qs.stringify(form)
  await put({
    data: formData,
    path: '/oauth/senha',
    host: API_HOST_V1,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const validateAccount = async (cpf: string): Promise<void> => {
  await get(`/contas-valida?q=${cpf}`)
}

export const checkout = async (props: CheckoutRequest, recaptchaToken: string): Promise<CheckoutResponse> => {
  const cookie = getCookie('prev_session')
  let prev_session: Record<string, string> = {}
  if (cookie) {
    prev_session = { 'X-Global-Session-User-ID': cookie }
  }

  const response = await PrevAxios.post(
    `${API_HOST_DASHBOARD_V2}/checkout`,
    props,
    {
      headers: {
        'g-recaptcha-response': recaptchaToken,
        ...prev_session
      }
    }
  )
  return response.data as CheckoutResponse
}

export const getStatusPix = async (uuidPagamento: string, token: string): Promise<StatusPixResponse> => {
  const response = await PrevAxios.get(`${API_HOST_DASHBOARD_V2}/pagamentos/${uuidPagamento}/consulta-status`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  return response.data
}

export const newMember = async (props: NewMemberProps): Promise<void> => {
  const data = qs.stringify(props)

  const url = `${API_HOST_V1}${'/oauth/membro'}`;
  await PrevAxios.post(url, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
}

export const postInivite = async (id: string) => {
  const data = await authPost({
    path: '/membros/convite',
    data: { id },
  })

  return data
}

export const changePrivilegio = async (id: number, privilegio: string) => {
  const data = await authPut({
    data: {
      privilegio: privilegio
    },
    path: `/membros/${id}/privilegio`
  })
  return data
}

export const changeUserImage = async (file) => {
  const form = new FormData()
  form.append("imagemDoPerfil", file);

  const response = await PrevAxios.patch(
    API_HOST_V1 + '/contas/imagem-perfil',
    form,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }
  )
  return response
}

export const deleteUserImage = async () => {
  const response = await authDelete({
    path: `/contas/imagem-perfil`
  })

  return response
}

export const getEspecialidades = async () => {
  const response = await authGet('/especialidades')
  return response
}

export interface Recibo {
  uuidDaFatura: string;
  valorBruto: number;
  valorDoDesconto: number;
  valorTotal: number;
  dataDoPagamento: string; // ou Date se você for converter para um objeto Date
  observacao: string;
  meioDePagamento: "CARTAO_DE_CREDITO" | "BOLETO" | "PIX"; // Enum ou string restrita
  cartaoDeCredito?: {
    id: number;
    nome: string;
    bandeira: string; // Exemplo: "VISA", "MASTERCARD", "HIPERCARD"
    primeirosSeisDigitos: string;
    ultimosQuatroDigitos: string;
    dataDeVencimento: string; // ou Date se você for converter para um objeto Date
  };
  periodo: {
    id: number;
    inicio: string; // ou Date
    fim: string; // ou Date
    motivo: string;
  };
  prestador: {
    cnpj: string;
    razaoSocial: string;
    endereco: {
      cidade: string;
      cep: string;
      tipoLogradouro: string;
      logradouro: string;
      complemento: string;
      uf: string;
      numero: string;
      bairro: string;
    };
  };
  tomador: {
    email: string;
    nome: string;
    numeroDoDocumento: string; // CPF ou CNPJ
    escritorioNome: string;
    escritorioCnpj: string; // Apenas CNPJ
  };
}

export const getRecibo = async (id: string): Promise<Recibo> => {
  const data = authGet(`/faturas/${id}/recibo-pagamento`)
  return data
}