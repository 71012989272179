import {
  PrevPlatformProvider,
  LoadingProvider
} from '@prev/ui-kit'
import React, { Suspense } from 'react'
import { LoginProvider } from './context/LoginContext'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { UseSearchInterface } from '@prev-front/mf-template'
import { FormAnimationProvider } from './context/FormAnimationContext'
import { EditOfficeProvider } from './context/EditOfficeContext'
import { AccountInfoProvider } from './context/AccountInfoContext'
import { FinanceiroProvider } from './context/FinanceiroContext'
import { getToken } from '@prev-front/shared-api'
import { AnimationHandlerProvider } from './context/AnimationHandlerContext'
import { ClientListProvider } from './context/ClientListContext'
import { ClientProvider } from './context/ClientContext'

const Login = React.lazy(
  () =>
    import(
      /* webpackChunkName: "login" */ './pages/Login'
    )
)

const Recovery = React.lazy(
  () =>
    import(
      /* webpackChunkName: "recovery" */ './pages/Recovery'
    )
)

const LoginWithoutOffice = React.lazy(
  () =>
    import(
      /* webpackChunkName: "loginWithoutOffice" */ './pages/LoginWithoutOffice'
    )
)

const ModalInvite = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modalInvite" */ './pages/InviteOffice'
    )
)


const Checkout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "checkout" */ './pages/Checkout'
    )
)


const Home = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-home" */ '../../mf-core/src/pages/Home'
    ).then(module => ({ default: module.Home }))
)

const OfficeInfo = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-officeInfo" */ './pages/OfficeInfo'
    ).then(module => ({ default: module.OfficeInfo }))
)

const UserAccount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-userAccount" */ './pages/UserAccount'
    ).then(module => ({ default: module.UserAccount }))
)

const FinanceiroView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-financeiro" */ './pages/Financeiro'
    ).then(module => ({ default: module.FinanceiroView }))
)

const Cursos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-cursos" */ './pages/Cursos'
    ).then(module => ({ default: module.Cursos }))
)

const Clientes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientes" */ './pages/Clientes'
    ).then(module => ({ default: module.Clientes }))
)

const ClientView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientView" */ './pages/ClientView'
    ).then(module => ({ default: module.ClientView }))
)

export interface RootProps {
  context: UseSearchInterface & { scrollTop: () => void, checkPaywall: () => boolean, toggleDarkMode: () => void }
}

export default function Root(props: RootProps): JSX.Element {

  const toggleDarkMode = () => { }

  const scrollTop = () => { }

  const checkPaywall = () => { }

  const token = getToken()

  const loginProviderRoutes = [
    '/login',
    '/password-recovery/',
    '/invitation-accepted/',
    '/login/sem-escritorio',
    '/checkout',
  ];

  const isLoginProviderRoute = loginProviderRoutes.includes(location.pathname);

  return (
    <PrevPlatformProvider>
      <LoadingProvider>
        <Suspense>
          {
            isLoginProviderRoute && (
              <LoginProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/password-recovery/" element={<Recovery />} />
                    <Route path="/invitation-accepted/" element={<ModalInvite />} />
                    <Route path="/login/sem-escritorio" element={<LoginWithoutOffice />} />
                    <Route path="/checkout" element={
                      <GoogleReCaptchaProvider
                        reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK"
                        language="pt-BR"
                      >
                        <Checkout />
                      </GoogleReCaptchaProvider>
                    } />
                    {/* Add new routes here without the LoginProvider */}
                  </Routes>
                </BrowserRouter>
              </LoginProvider>
            )
          }
          <BrowserRouter>
            <Routes>
              <Route path="/clientes" Component={() => (
                <ClientListProvider>
                  <AnimationHandlerProvider>
                    <Clientes searchContext={props?.context} />
                  </AnimationHandlerProvider>
                </ClientListProvider>
              )} />
              <Route path="/clientes/:cpf" Component={() => (
                <ClientProvider>
                  <AnimationHandlerProvider>
                    <ClientView />
                  </AnimationHandlerProvider>
                </ClientProvider>
              )} />
              <Route
                path="/"
                element={
                  <FormAnimationProvider searchContext={props.context}>
                    <Home context={props.context} />
                  </FormAnimationProvider>
                }
              />
              <Route
                path="/configuracoes"
                element={
                  <EditOfficeProvider>
                    <FormAnimationProvider searchContext={props.context}>
                      <OfficeInfo />
                    </FormAnimationProvider>
                  </EditOfficeProvider>
                }
              />
              <Route
                path="/configuracoes/conta"
                element={
                  <AccountInfoProvider>
                    <FormAnimationProvider searchContext={props.context}>
                      <UserAccount setLayoutDarkMode={toggleDarkMode} />
                    </FormAnimationProvider>
                  </AccountInfoProvider>
                }
              />
              <Route
                path="/configuracoes/financeiro"
                element={
                  <FinanceiroProvider>
                    <FinanceiroView />
                  </FinanceiroProvider>
                }
              />
              <Route
                path="/cursos"
                element={
                  <FinanceiroProvider>
                    <Cursos searchContext={props.context} />
                  </FinanceiroProvider>
                }
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </LoadingProvider>
    </PrevPlatformProvider>
  )
}