import { SearchContextInterface, UserContextInterface } from '@prev-front/mf-template';
import React, { createContext } from 'react'

export const CoreContext = createContext<CoreContextInterface>({} as any)

type CoreContextInterface = {
    search: SearchContextInterface
    user: UserContextInterface
};


export const CoreProvider = ({ children, context }): JSX.Element => {
    return (
        <CoreContext.Provider value={context as CoreContextInterface}>
            {children}
        </CoreContext.Provider>
    )
}
