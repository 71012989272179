/* eslint-disable */
/* tslint:disable */

import moment from 'moment'

export const validatePassword = async (_, value): Promise<any> => {
  const errors: any[] = []
  if (!value) errors.push('Por favor, insira sua senha')
  if (value?.length < 8) {
    errors.push('A senha deve conter pelo menos 8 caracteres')
  }
  if (!/[a-z]/.test(value)) {
    errors.push('A senha deve conter ao menos uma letra minúscula')
  }
  if (!/[A-Z]/.test(value)) {
    errors.push('A senha deve conter ao menos uma letra maiúscula')
  }
  if (!/[0-9]/.test(value)) {
    errors.push('A senha deve conter ao menos um dígito numérico')
  }
  if (!/[!@#$%^&*()_+=\-]/.test(value)) {
    errors.push('A senha deve conter ao menos um caractere especial')
  }
  errors.length ? await Promise.reject(errors) : await Promise.resolve()
}

export const validateDate = async (_, value): Promise<undefined> => {
  // Regex para validar os formatos DD / AAAA ou DD / AA
  const isValid = /^(\d{2} \/ \d{4}|\d{2} \/ \d{2})$/.test(value)
  if (isValid) {
    await Promise.resolve(); return
  }
  await Promise.reject(new Error('Por favor, insira uma data válida no formato DD / AAAA ou DD / AA.'))
}

export const validateExpiryDate = async (_, value): Promise<undefined> => {
  if (!value) {
    // Se não houver valor, não precisa validar
    await Promise.resolve(); return
  }

  // Tentativa de parsear a data assumindo primeiro MM/YY e depois MM/YYYY
  const dateFormats = ['MM / YY', 'MM / YYYY']
  let expiryDate: any = null

  for (const format of dateFormats) {
    const parsedDate = moment(value, format, true)
    if (parsedDate.isValid()) {
      expiryDate = parsedDate
      break
    }
  }

  // Se nenhuma formatação válida, retorna erro
  if (!expiryDate) {
    await Promise.reject(new Error('Formato de data inválido. Use MM / AA ou MM / AAAA.')); return
  }

  // Verifica se a data de validade é durante ou após o mês atual
  // Para isso, usa-se o início do próximo mês como referência
  const startOfNextMonth = moment().add(1, 'month').startOf('month')
  if (expiryDate.isBefore(startOfNextMonth)) {
    await Promise.reject(new Error('A data de validade deve ser maior que o mês atual.')); return
  }

  await Promise.resolve()
}

export const handleCardExpiryDate = (date: string): string => {
  let expiryDate = date
  const parts = expiryDate.split('/')

  if (parts.length === 2) {
    let year = parts[1].trim()

    // Verifica se o ano está em formato de dois dígitos
    if (year.length === 2) {
      // Adiciona "20" ao início do ano se estiver entre 00 e 99
      year = '20' + year
    }

    // Reconstroi a data no formato DD / YYYY
    expiryDate = `${year}-${parts[0].trim()}`
  }

  return expiryDate
}

export const validateFullName = async (_, value): Promise<void> => {
  if (!value) {
    await Promise.reject(new Error('Insira o nome completo')); return
  }
  if (!/^\S+\s+\S+/.test(value)) {
    await Promise.reject(new Error('Insira pelo menos um nome e um sobrenome')); return
  }
  await Promise.resolve()
}

export const slugToUrl = (slug: string) => {
  // Extrai o domínio atual da URL
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('//')
  const protocol = urlParts[0]
  const domain = urlParts[1].split('/')[0]
  
  // Atualiza a URL com o slug
  const newUrl = `${protocol}//${slug}.${domain}`
  window.location.assign(newUrl)
}


export const getSlugFromUrl = (): string | null => {
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('//')
  const isLocal = checkLocalhost()

  if (urlParts.length > 1) {
    const domainAndPath = urlParts[1].split('/')
    const domainParts = domainAndPath[0].split('.')
    console.log(domainParts)

    // Verifica se há um subdomínio e retorna o slug
    if (!isLocal && domainParts.length > 2) {
      console.log("entrei primeiro")
      return domainParts[0]
    }
    if(isLocal && domainParts.length > 1) {
      console.log("entrei sec")
      return domainParts[0]
    }
  }

  return null
}

export const checkLocalhost = (): boolean => {
  const currentUrl = window.location.href
  const isLocal = currentUrl.includes('localhost')
  console.log('islocal', isLocal)
  return isLocal
}

export const removeSlugFromUrl = (): void => {
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('//')

  if (urlParts.length > 1) {
    const domainAndPath = urlParts[1].split('/')
    const domainParts = domainAndPath[0].split('.')

    // Verifica se há um subdomínio e remove o slug
    if (domainParts.length > 1) {
      const newDomain = domainParts.slice(1).join('.')
      const newUrl = `${urlParts[0]}//${newDomain}/${domainAndPath.slice(1).join('/')}`
      window.location.replace(newUrl)
    }
  }
}