import { Empty, usePrevTheme, Row, Spin } from '@prev/ui-kit'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

export interface LocalLoadingProps {
    display: boolean
    style?: React.CSSProperties
    iconSize?: string
    errorMessage?: string
    backgroundColor?: string
}

export const LocalLoading = (props: LocalLoadingProps): JSX.Element => {
    const { display, style, iconSize, backgroundColor } = props
    const { theme } = usePrevTheme()
    const LoadingIcon = (
        <LoadingOutlined style={{ fontSize: iconSize ?? '50px' }} />
    )

    if (!display) return <></>

    return (
        <Row
            style={{
                position: 'absolute',
                zIndex: 50,
                backgroundColor:
                    backgroundColor || theme.color.surface.container.default,
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                left: 0,
                top: 0,
                justifyContent: 'center',
                alignItems: 'center',
                ...style
            }}
        >
            {!props.errorMessage ? (
                <Spin
                    data-testid='spin-loading'
                    indicator={LoadingIcon}
                    style={{ color: theme.color.text.brand }}
                />
            ) : (
                <Empty data-testid='empty-loading' description={props.errorMessage} />
            )}
        </Row>
    )
}
