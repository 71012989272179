import {
  PrevPlatformProvider,
  LoadingProvider
} from '@prev/ui-kit'
import React, { Suspense } from 'react'
import { LoginProvider } from './context/LoginContext'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { EditOfficeProvider } from './context/EditOfficeContext'
import { AccountInfoProvider } from './context/AccountInfoContext'
import { FinanceiroProvider } from './context/FinanceiroContext'
import { ClientListProvider } from './context/ClientListContext'
import { ClientProvider } from './context/ClientContext'
import { CoreProvider } from './context/CoreProvider'
import { SearchContextInterface, UserContextInterface } from '@prev-front/mf-template'
import FinanceiroRecibo from './components/financeiro/recibo/Recibo'

const Login = React.lazy(
  () =>
    import(
      /* webpackChunkName: "login" */ './pages/Login'
    )
)

const Recovery = React.lazy(
  () =>
    import(
      /* webpackChunkName: "recovery" */ './pages/Recovery'
    )
)

const LoginWithoutOffice = React.lazy(
  () =>
    import(
      /* webpackChunkName: "loginWithoutOffice" */ './pages/LoginWithoutOffice'
    )
)

const ModalInvite = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modalInvite" */ './pages/InviteOffice'
    )
)


const Checkout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "checkout" */ './pages/Checkout'
    )
)


const Home = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-home" */ '../../mf-core/src/pages/Home'
    ).then(module => ({ default: module.Home }))
)

const OfficeInfo = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-officeInfo" */ './pages/OfficeInfo'
    ).then(module => ({ default: module.OfficeInfo }))
)

const UserAccount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-userAccount" */ './pages/UserAccount'
    ).then(module => ({ default: module.UserAccount }))
)

const FinanceiroView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-financeiro" */ './pages/Financeiro'
    ).then(module => ({ default: module.FinanceiroView }))
)

const Cursos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-cursos" */ './pages/Cursos'
    ).then(module => ({ default: module.Cursos }))
)

const Clientes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientes" */ './pages/Clientes'
    ).then(module => ({ default: module.Clientes }))
)

const ClientView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientView" */ './pages/ClientView'
    ).then(module => ({ default: module.ClientView }))
)

export interface RootProps {
  context: {
    themeProps: any
    searchProps: SearchContextInterface
    userProps: UserContextInterface
  }
}

const loginProviderRoutes = [
  '/login',
  '/password-recovery/',
  '/invitation-accepted/',
  '/login/sem-escritorio',
  '/checkout',
  '/configuracoes/financeiro/recibo'
];

export default function Root(props: RootProps): JSX.Element {
  const isLoginProviderRoute = loginProviderRoutes.some((route) => location.pathname.startsWith(route));
  return (
    <LoadingProvider>
      <Suspense>
        {
          isLoginProviderRoute && (
            <PrevPlatformProvider>
              <LoginProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/password-recovery/" element={<Recovery />} />
                    <Route path="/invitation-accepted/" element={<ModalInvite />} />
                    <Route path="/login/sem-escritorio" element={<LoginWithoutOffice />} />
                    <Route path="/configuracoes/financeiro/recibo/:id" element={<FinanceiroRecibo />} />
                    <Route path="/checkout" element={
                      <GoogleReCaptchaProvider
                        reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK"
                        language="pt-BR"
                      >
                        <Checkout />
                      </GoogleReCaptchaProvider>
                    } />
                  </Routes>
                </BrowserRouter>
              </LoginProvider>
            </PrevPlatformProvider>
          )
        }
        {
          !isLoginProviderRoute && (
            <CoreProvider context={{ search: props.context.searchProps, user: props.context.userProps }}>
              <PrevPlatformProvider context={{ theme: props.context.themeProps.theme, darkMode: props.context.themeProps.darkMode, toggleDarkMode: props.context.themeProps.toggleDarkMode }}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/clientes" Component={() => (
                      <ClientListProvider>
                        <Clientes />
                      </ClientListProvider>
                    )} />
                    <Route path="/clientes/:cpf" Component={() => (
                      <ClientListProvider>
                        <ClientProvider>
                          <ClientView />
                        </ClientProvider>
                      </ClientListProvider>
                    )} />
                    <Route
                      path="/"
                      element={
                        <Home />
                      }
                    />
                    <Route
                      path="/configuracoes/conta"
                      element={
                        <AccountInfoProvider>
                          <UserAccount />
                        </AccountInfoProvider>
                      }
                    />
                    <Route
                      path="/configuracoes"
                      element={
                        <EditOfficeProvider>
                          <OfficeInfo />
                        </EditOfficeProvider>
                      }
                    />

                    <Route
                      path="/configuracoes/financeiro"
                      element={
                        <FinanceiroProvider>
                          <FinanceiroView />
                        </FinanceiroProvider>
                      }
                    />
                    <Route
                      path="/cursos"
                      element={
                        <FinanceiroProvider>
                          <Cursos />
                        </FinanceiroProvider>
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </PrevPlatformProvider>
            </CoreProvider >
          )
        }
      </Suspense>
    </LoadingProvider >
  )
}