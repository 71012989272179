import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { LocalLoading } from "./shared-components/LocalLoading"
import { useScrollEnd } from "./shared-components/useScrollEnd"
import useWidth from "./shared-components/useWidth"

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: (props: any) => (
    <Root {...props} />
  ),
  errorBoundary() {
    return <div className="module-load-error"></div>
  }
})

export const { bootstrap, mount, unmount } = lifecycles
export { LocalLoading, useWidth, useScrollEnd }