import { useContext, useEffect, useState } from 'react'
import { type PricingParams, getPricing, type Fatura, getFaturas, getPagamentos, type PaymentInfo } from '../services'
import { ApiError, PricingResponse } from '@prev-front/shared-api'
import { notification } from '@prev/ui-kit'
import { CoreContext } from '../context/CoreProvider'

export interface UseFinanceiroInterface {
  pricing: any | undefined
  faturas: Fatura[] | undefined
  pagamentos: PaymentInfo[] | undefined
}

export const useFinanceiro = (): UseFinanceiroInterface => {
  const { user: { userInfo } } = useContext(CoreContext)
  const [pricing, setPricing] = useState<PricingResponse | undefined>(undefined)
  const [faturas, setFaturas] = useState<Fatura[]>()
  const [pagamentos, setPagamentos] = useState<PaymentInfo[] | undefined>()

  const fetchPricing = async (payload: PricingParams): Promise<void> => {
    try {
      const data = await getPricing(payload)
      setPricing(data)
    } catch (err) {
      if (err instanceof ApiError) {
        notification.error({ message: err.message })
      }
      if (!(err instanceof ApiError)) {
        notification.error({ message: 'Algo deu errado ao tentar carregar preço, tente novamente mais tarde' })
      }
    }
  }

  const fetchFaturas = async (): Promise<void> => {
    try {
      const data = await getFaturas()
      setFaturas(data)
    } catch (err) {
      if (err instanceof ApiError) {
        notification.error({ message: err.message })
      }
      if (!(err instanceof ApiError)) {
        notification.error({ message: 'Algo deu errado ao tentar carregar faturas, tente novamente mais tarde' })
      }
    }
  }

  const fetchPagamentos = async (): Promise<void> => {
    try {
      const pagamentosResponse = await getPagamentos()
      setPagamentos(pagamentosResponse)
    } catch (err) {
      notification.error({ message: err.message ?? 'Algo deu errado ao buscar pagamentos' })
    }
  }

  useEffect(() => {
    void fetchFaturas()
    void fetchPagamentos()
  }, [])

  useEffect(() => {
    if (userInfo) {
      void fetchPricing({
        intervalo: userInfo.assinatura.intervalo,
        tipoDeEvento: 'ALTERACAO',
        tipoDeMeioDePagamento: userInfo.assinatura.meioDePagamento,
        tiposDeModulo: userInfo.assinatura.tiposDeModulo
      })
    }
  }, [userInfo])

  return {
    pricing,
    faturas,
    pagamentos
  }
}
