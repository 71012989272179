import { useContext, useEffect, useState } from 'react'
import { type CasesConsumptionI, getCasesConsumption, getInfo, type PricingParams, getPricing, type Fatura, getFaturas, getPagamentos, type PaymentInfo } from '../services'
import { type UserInfoResponse } from '../services/types'
import { ApiError, PricingResponse } from '@prev-front/shared-api'
import { Message } from '@prev/ui-kit'

export interface UseFinanceiroInterface {
  info: UserInfoResponse | undefined
  setInfo: any
  casesConsumption: CasesConsumptionI | undefined
  fetchInfo: () => Promise<void>
  pricing: PricingResponse | undefined
  faturas: Fatura[] | undefined
  pagamentos: PaymentInfo[] | undefined
}

export const useFinanceiro = (): UseFinanceiroInterface => {
  const [info, setInfo] = useState<UserInfoResponse | undefined>()
  const [casesConsumption, setCasesConsumption] = useState<CasesConsumptionI>()
  const [pricing, setPricing] = useState<PricingResponse | undefined>(undefined)
  const [faturas, setFaturas] = useState<Fatura[]>()
  const [pagamentos, setPagamentos] = useState<PaymentInfo[] | undefined>()

  const fetchInfo = async (): Promise<void> => {
    try {
      const info = await getInfo()
      setInfo(info)
    } catch(err) {
      console.log(err)
    }
  }

  const fetchCasesConsumption = async (): Promise<void> => {
    try {
      const casesConsumption = await getCasesConsumption()
      setCasesConsumption(casesConsumption)
    } catch (err) {
      Message.error( err.message ?? "Algo deu errado ao pesquisar informações sobre consumo")
    }
  }

  const fetchPricing = async (payload: PricingParams): Promise<void> => {
    try {
      const data = await getPricing(payload)
      setPricing(data)
    } catch (err) {
      if (err instanceof ApiError) {
        Message.error(err.message)
      }
      if (!(err instanceof ApiError)) {
        Message.error('Algo deu errado ao tentar carregar preço, tente novamente mais tarde')
      }
    }
  }

  const fetchFaturas = async (): Promise<void> => {
    try {
      const data = await getFaturas()
      setFaturas(data)
    } catch (err) {
      if (err instanceof ApiError) {
        Message.error(err.message)
      }
      if (!(err instanceof ApiError)) {
        Message.error('Algo deu errado ao tentar carregar faturas, tente novamente mais tarde')
      }
    }
  }

  const fetchPagamentos = async (): Promise<void> => {
    try {
      const pagamentosResponse = await getPagamentos()
      setPagamentos(pagamentosResponse)
    } catch (err) {
      Message.error(err.message ?? 'Algo deu errado ao buscar pagamentos')
    }
  }

  useEffect(() => {
    void fetchInfo()
    void fetchCasesConsumption()
    void fetchFaturas()
    void fetchPagamentos()
  }, [])

  useEffect(() => {
    if (info) {
      void fetchPricing({
        intervalo: info.assinatura.intervalo,
        tipoDeEvento: 'ALTERACAO',
        tipoDeMeioDePagamento: info.assinatura.meioDePagamento,
        tiposDeModulo: info.assinatura.tiposDeModulo
      })
    }
  }, [info])

  return {
    info,
    setInfo,
    casesConsumption,
    fetchInfo,
    pricing,
    faturas,
    pagamentos
  }
}
