import {
  Text,
  Form,
  FieldInput,
  Row,
  Col,
  Switch,
  type FormInstance,
  Label,
  usePrevTheme,
  notification
} from '@prev/ui-kit'
import React, { useContext, useEffect, useState } from 'react'
import { CLIENT_SHEET_HEADERS } from '../../utils'
import { ClientContext } from '../../context/ClientContext'
import { updateClient, type AttendanceSheet } from '../../services'
import { AnimationHandlerContext } from '../../context/AnimationHandlerContext'

interface CreateCLientSheet {
  form: FormInstance<AttendanceSheet>
}

export const CreateClientSheet = (props: CreateCLientSheet): JSX.Element => {
  const { fetchClient, clientData } = useContext(ClientContext)
  const { form } = props
  const { theme } = usePrevTheme()
  const { createSheet } = useContext(AnimationHandlerContext)
  const [textArea, setTextArea] = useState(
    clientData?.fichaDeAtendimento?.descricaoRiscoDeSaudeOuIntegridadeFisica
  )

  useEffect(() => {
    if (clientData?.fichaDeAtendimento) {
      form.setFieldsValue(clientData?.fichaDeAtendimento)
    }
  }, [clientData])

  const onChangeTextArea = (event): void => {
    const { target } = event
    setTextArea(target.value)
    form.setFieldValue('descricaoRiscoDeSaudeOuIntegridadeFisica', target.value)
  }

  const onSubmit = async (values: AttendanceSheet): Promise<void> => {
    try {
      await updateClient({
        cpf: clientData?.cpf ?? '',
        payload: {
          ...clientData,
          fichaDeAtendimento: {
            ...values,
            responsaveis: values.responsaveis.map((item) => ({
              nome: item
            }))
          }
        }
      })
      await fetchClient()
      notification.success({
        message: 'Cliente removido',
        placement: 'bottomRight'
      })
      createSheet.setIsVisibleSheet(false)
      form.resetFields()
    } catch (err) {
      void notification.error(
        {
          message: 'Erro ao atualizar ficha de atendimento, verifique os campos e tente novamente',
          placement: "topRight"
        }
      )
    }
  }

  return (
    <Col>
      <Col style={{ padding: '0 130px' }}>
        <Text emphasis style={{ marginBottom: '10px' }} size="h6">
          Autorização para informações
        </Text>
        <Text style={{ marginBottom: '20px' }} color={theme.color.text.subtle}>
          Autorizo as pessoas abaixo listadas a terem acesso a qualquer
          informação sobre meus dados e processos relativos à Direito
          Previdenciário neste escritório.
        </Text>
        <Form
          data-testid='form-autorizacao'
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center'
          }}
          form={form}
          onFinish={onSubmit}
        >
          <Label>
            Nome completo do responsável
            <Form.Item
              name={['responsaveis', 0]}
              initialValue={clientData?.fichaDeAtendimento?.responsaveis[0] ?? ''}
            >
              <FieldInput
                data-testid='input-nome-responsavel'
                style={{ width: '300px' }}
                placeholder="Digite o nome completo do responsável"
              />
            </Form.Item>
          </Label>
          {/* {
            qtdResponsaveis.map((e, index) => (
              <Form.Item key={'index'} name={['responsaveis', 0]} rules={[{ required: true, message: 'É necessário informar ao menos um responsável' }]} initialValue={clientData?.fichaDeAtendimento?.responsaveis[0] ?? ''}>
              <Row style={{ alignItems: 'flex-end', gap: '20px' }}>
                <FieldInput label='Nome completo do responsável' placeholder='Digite o nome completo do responsável' style={{ width: '300px' }} />
                {
                  index === qtdResponsaveis.length - 1 && index <= 1 && (
                    <Button
                      type='subtle'
                      icon={<PlusCircleOutlined />}
                    >
                      Adicionar outro responsável
                    </Button>
                  )
                }
              </Row>
            </Form.Item>
            ))
          } */}
          <Text style={{ marginBottom: '20px' }} size="h6">
            Informações complementares
          </Text>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <Row
              style={{
                width: '100%',
                height: '56px',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px'
              }}
            >
              <Text style={{ marginBottom: '5px' }}>
                Nasceu em zona rural e trabalhou em regime de economia familiar?
              </Text>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="nasceuZonaRuralEconomiaFamiliar"
                initialValue={
                  clientData?.fichaDeAtendimento
                    ?.nasceuZonaRuralEconomiaFamiliar ?? false
                }
              >
                <Switch data-testid='switch-zona-rural' />
              </Form.Item>
            </Row>
            <Row style={{ gap: '20px' }}>
              <Form.Item shouldUpdate style={{ padding: 0, margin: 0 }}>
                {({ getFieldValue }) => {
                  const nasceuZonaRuralEconomiaFamiliar = getFieldValue(
                    'nasceuZonaRuralEconomiaFamiliar'
                  )
                  return (
                    nasceuZonaRuralEconomiaFamiliar === true && (
                      <Label>
                        Possuía empregados? Quantos?
                        <Form.Item
                          rules={[
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Por favor, informe um número válido.',
                              required: true
                            }
                          ]}
                          name="totalDeEmpregados"
                          initialValue={
                            clientData?.fichaDeAtendimento?.totalDeEmpregados ?? 0
                          }
                        >
                          <FieldInput
                            data-testid='input-possuia-empregado'
                            placeholder="Quantidade de empregados"
                          />
                        </Form.Item>
                      </Label>
                    )
                  )
                }}
              </Form.Item>
              <Form.Item shouldUpdate style={{ padding: 0, margin: 0 }}>
                {({ getFieldValue }) => {
                  const nasceuZonaRuralEconomiaFamiliar = getFieldValue(
                    'nasceuZonaRuralEconomiaFamiliar'
                  )
                  return (
                    nasceuZonaRuralEconomiaFamiliar === true && (
                      <Label>
                        Se sim, quantos hectares possuía?
                        <Form.Item
                          rules={[
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Por favor, informe um número válido.',
                              required: true
                            }
                          ]}
                          name="totalDeHectares"
                          initialValue={
                            clientData?.fichaDeAtendimento?.totalDeHectares ?? 0
                          }
                        >
                          <FieldInput
                            data-testid='input-hectares'
                            placeholder="Hectares"
                          />
                        </Form.Item>
                      </Label>
                    )
                  )
                }}
              </Form.Item>
            </Row>
            <Row
              style={{
                width: '100%',
                height: '56px',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                backgroundColor: theme.color.surface.default,
                borderRadius: '5px'
              }}
            >
              <Text style={{ marginBottom: '5px' }}>
                Teve risco de saúde ou integridade física?
              </Text>
              <Form.Item
                style={{ marginBottom: 0 }}
                valuePropName="value"
                name="teveRiscoDeSaudeOuIntegridadeFisica"
                initialValue={
                  clientData?.fichaDeAtendimento
                    ?.nasceuZonaRuralEconomiaFamiliar ?? false
                }
              >
                <Switch
                  data-testid='switch-teve-risco-saude'
                  defaultChecked={
                    clientData?.fichaDeAtendimento
                      ?.teveRiscoDeSaudeOuIntegridadeFisica
                  }
                />
              </Form.Item>
            </Row>
            <Form.Item
              shouldUpdate
              style={{ padding: 0, margin: '10px 0', minHeight: 0 }}
            >
              {({ getFieldValue }) => {
                const teveRiscoDeSaudeOuIntegridadeFisica = getFieldValue(
                  'teveRiscoDeSaudeOuIntegridadeFisica'
                )
                return (
                  teveRiscoDeSaudeOuIntegridadeFisica === true && (
                    <Form.Item
                      style={{ minHeight: 0 }}
                      name="descricaoRiscoDeSaudeOuIntegridadeFisica"
                      initialValue={
                        clientData?.fichaDeAtendimento
                          ?.descricaoRiscoDeSaudeOuIntegridadeFisica ?? ''
                      }
                    >
                      <Label>
                        <Text color={theme.color.text.subtle} size="body">
                          Descrição risco de saude ou integridade fisica
                        </Text>
                        <FieldInput.TextArea
                          style={{ width: '400px' }}
                          size="large"
                          value={textArea}
                          onChange={onChangeTextArea}
                        />
                      </Label>
                    </Form.Item>
                  )
                )
              }}
            </Form.Item>
            {CLIENT_SHEET_HEADERS.map((header, index) => (
              <Row
                key={header.label}
                style={{
                  width: '100%',
                  height: '56px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                  backgroundColor:
                    index % 2 === 0
                      ? theme.color.surface.container.default
                      : theme.color.surface.default,
                  borderRadius: '5px'
                }}
              >
                <Text style={{ marginBottom: '5px' }}>{header.label}</Text>
                <Form.Item
                  name={header.value}
                  initialValue={
                    clientData?.fichaDeAtendimento?.[header.value] ?? false
                  }
                  style={{ marginBottom: 0 }}
                >
                  <Switch
                    data-testid='switch-ficha-atendimento'
                    defaultChecked={
                      clientData?.fichaDeAtendimento?.[header.value]
                    }
                  />
                </Form.Item>
              </Row>
            ))}
          </Col>
        </Form>
      </Col>
    </Col>
  )
}
