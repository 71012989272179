import { useContext, useState } from 'react'
import { getClients } from '../services'
import { getToken } from '@prev-front/shared-api'
import { notification } from 'antd'

interface GetCLientsInterface {
  clients: any
  totalItems: any
  totalPages: any
  currentPage: any
}

interface handleGetClientsProps {
  pagina: number
  limite: number
  query?: string
}

interface FetchClients {
  pagina: number
  limite: number
  query?: string
}

export interface ClientsI {
  nome: string
  cpf: string
  idade: number
  dataDeNascimento: string
  dataDaUltimaAtualizacao: string
}

export interface FormatedData {
  nome: JSX.Element
  idade: number
  dataDeNascimento: string
  dataDaUltimaAtualizacao: string
  avatar: JSX.Element
  more: JSX.Element
  cpf: string
}

export interface UseClientsInterface {
  handleGetClients: (handleGetClientsProps: handleGetClientsProps) => Promise<GetCLientsInterface>
  pagination: {
    currentPage: number
    pageSize: number
    total: number
    showing: number
  }
  setPagination: React.Dispatch<React.SetStateAction<{
    currentPage: number
    pageSize: number
    total: number
    showing: number
  }>>
  clients: ClientsI[]
  isLoading: boolean
  fetchClients: (FetchClients: FetchClients) => Promise<void>
  setIsLoading: (boolean: boolean) => void
}

export const useClients = (): UseClientsInterface => {
  const [clients, setClients] = useState<ClientsI[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 8,
    total: 0,
    showing: 0
  })

  const fetchClients = async (props: FetchClients): Promise<void> => {
    try {
      setIsLoading(true)
      const { clients, totalItems, currentPage } = await handleGetClients({ ...props })
      setClients(clients)
      setPagination({
        ...pagination,
        total: totalItems,
        showing: clients?.length > 0 ? clients.length : 0,
        currentPage: clients.length > 0 ? currentPage : 1
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      notification.error({
        message: err.message ?? "Algo deu errado, tente novamente mais tarde"
      })
    }
  }

  const handleGetClients = async (props: handleGetClientsProps): Promise<GetCLientsInterface> => {
    const { limite, pagina, query } = props
    const localToken = getToken() || ""

    const response = await getClients({
      limite,
      pagina,
      ordenaPor: 'SCORE',
      token: localToken,
      query: query ?? "",
    })

    const totalItems = response.headers.totaldeitens
    const totalPages = response.headers.totaldepaginas
    const currentPage = response.headers.pagina
    const clients = response.data.length > 0 ? response.data : []

    return {
      clients,
      totalItems,
      totalPages,
      currentPage
    }
  }

  return {
    handleGetClients,
    pagination,
    setPagination,
    clients,
    isLoading,
    fetchClients,
    setIsLoading
  }
}
