import { Button, Row, usePrevTheme } from '@prev/ui-kit'
import React, { useContext } from 'react'
import { AccountInfoContext } from '../../context/AccountInfoContext'
import { EditOfficeContext } from '../../context/EditOfficeContext'

interface ClientFooterProps {
  onClose: any
  form?: any
  page: string
  loading?: boolean
}

export const FormFooter = (props: ClientFooterProps): JSX.Element => {
  const { form, onClose, page, loading } = props
  const { theme } = usePrevTheme()
  const { accountInfo } = useContext(AccountInfoContext)
  const { office } = useContext(EditOfficeContext)

  return (
    <Row
      style={{
        width: '100%',
        height: '75px',
        zIndex: '99999',
        justifyContent: 'center',
        alignContent: 'center',
        borderTop: `1px solid ${theme.color.border.default}`
      }}
    >
      <Row
        style={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '10px 30px',
          width: '100%',
          gap: '20px',
          height: '100%',
          backgroundColor: theme.color.surface.container.default
        }}
      >
        <Button
          type="subtle"
          onClick={() => onClose(page === 'OFFICE' ? office : accountInfo)}
        >
          Cancelar
        </Button>
        <Button disabled={loading} type="primary" style={{ color: 'white' }} onClick={form.submit}>
          Enviar
        </Button>
      </Row>
    </Row>
  )
}
