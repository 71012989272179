import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  type FormInstance,
  Text,
  Form,
  FieldInput,
  Row,
  Card,
  Select,
  Tooltip,
  validateCnpj,
  usePrevTheme,
  Label,
  Message
} from '@prev/ui-kit'
import { ajustHifen, estados, estadosOab } from '../../../utils'
import { EditOfficeContext } from '../../../context/EditOfficeContext'
import { type OfficeInfoResponse } from '../../../services/types'
import { cleanObject, getEnderecoByCep, putOfficeInfo } from '../../../services'
import { FormAnimationContext } from '../../../context/FormAnimationContext'
import { LocalLoading } from '../../../shared-components/LocalLoading'

interface AccountInfoProps {
  form: FormInstance<
    OfficeInfoResponse & { ufOab?: string; numeroOabClean?: string }
  >
}

export const EditOfficeForm = (props: AccountInfoProps): JSX.Element => {
  const { theme } = usePrevTheme()
  const { office, fetchOfficeInfo } = useContext(EditOfficeContext)
  const [isLoading, setIsLoading] = useState(false)
  const { officeFormView } = useContext(FormAnimationContext)

  const fetchAddress = async (cep: string): Promise<void> => {
    try {
      const onlyNumbersCep = cep.replace(/\D/g, '')
      const endereco = await getEnderecoByCep(onlyNumbersCep)
      if (endereco.numero) delete endereco.numero
      props.form.setFieldsValue({ endereco })
    } catch (err) {
      console.log("err fetchAddress:", err)
    }
  }

  const handleSubmit = async (
    values: OfficeInfoResponse & { ufOab?: string; numeroOabClean?: string }
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const payload = {
        ...values
      }
      if (payload.ufOab && payload.numeroOabClean) {
        payload.numeroDaOAB = handleOabMask()
      }
      delete payload.ufOab
      delete payload.numeroOabClean
      const final = cleanObject(payload)
      await putOfficeInfo(final)
      void fetchOfficeInfo()
      setIsLoading(false)
      officeFormView.setOfficeForm(false)
      Message.success('Escritório atualizado com sucesso')
    } catch (err) {
      setIsLoading(false)
      Message.error(err?.message || 'Erro ao atualizar escritório')
    }
  }

  const handleOabMask = (): string => {
    const uf = props.form.getFieldValue('ufOab')
    const numberOab = props.form
      .getFieldValue('numeroOabClean')
      ?.replace(/\D/g, '')
    const final = `OAB/${uf} ${addDotAfterHundred(numberOab)}`
    return final
  }

  const addDotAfterHundred = (oabNumber: string): string => {
    if (oabNumber?.length >= 4) {
      oabNumber = oabNumber.slice(0, -3) + '.' + oabNumber.slice(-3)
    }
    return oabNumber
  }

  const cnpjValidator = async (_, value): Promise<undefined> => {
    if (!value || validateCnpj(value)) {
      await Promise.resolve()
      return
    }
    return await Promise.reject(new Error('CNPJ inválido!'))
  }

  useEffect(() => {
    if (office) props.form.setFieldsValue(office)
  }, [office])

  if (isLoading)
    return (
      <LocalLoading
        display={isLoading}
        style={{ height: '100vh', overflow: 'hidden' }}
      />
    )

  return (
    <Row>
      <Col
        sm={24}
        md={24}
        lg={24}
        xl={18}
        style={{
          padding: `0 20px 20px ${window.innerWidth <= 1200 ? '20px' : '130px'
            }`
        }}
      >
        <Text emphasis style={{ marginBottom: '40px' }} size="h6">
          Dados do escritório
        </Text>
        <Form data-testid='form-editr-office' form={props.form} onFinish={handleSubmit}>
          <Row gutter={12} style={{ marginBottom: '30px' }}>
            <Col span={12}>
              <Label>
                Nome do escritório
                <Form.Item
                  name="nome"
                  rules={[
                    { required: true, message: 'Digite o nome do escritório' }
                  ]}
                >
                  <FieldInput
                    data-testid='input-nome-escritorio'
                    placeholder="Digite nome do escritório aqui"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={6}>
              <Tooltip
                data-testid='tooltip-alterar-slug'
                title="Altere em configurações do escritório"
                placement="top"
              >
                <Label>
                  Slug do escritório
                  <Form.Item name="slug">
                    <FieldInput
                      data-testid='input-slug'
                      disabled
                      style={{ width: '100%' }}
                      placeholder="Digite o slug aqui"
                    />
                  </Form.Item>
                </Label>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Label>
                CNPJ
                <Form.Item
                  name="cnpj"
                  rules={[{ validator: cnpjValidator, message: 'CNPJ inválido' }]}
                >
                  <FieldInput.Cnpj
                    data-testid='input-cnpj'
                    name='input-cnpj'
                    style={{ width: '100%' }}
                    placeholder="Digite o CNPJ aqui"
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={10}>
              <Label>
                UF OAB
                <Form.Item
                  name="ufOab"
                  rules={[{ required: true, message: 'Selecione o UF da OAB' }]}
                >
                  <Select data-testid='select-uf-oab' options={estadosOab} />
                </Form.Item>
              </Label>
            </Col>
            <Col span={14}>
              <Label>
                Número OAB
                <Form.Item
                  rules={[
                    {
                      message: 'Por favor, informe um número válido.',
                      required: true,
                      max: 7
                    }
                  ]}
                  name="numeroOabClean"
                >
                  <FieldInput
                    data-testid='numero-oab'
                    onChange={(event) => {
                      props.form.setFieldValue(
                        'numeroOabClean',
                        addDotAfterHundred(event.target.value?.replace(/\D/g, ''))
                      )
                    }}
                    style={{ width: '100%' }}
                    maxLength={7}
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Text
            emphasis
            size="h6"
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            Dados de contato do escritório
          </Text>
          <Row gutter={12}>
            <Col span={12}>
              <Label>
                Email
                <Form.Item
                  validateDebounce={500}
                  name="email"
                  rules={[{ type: 'email', message: 'Email inválido' }]}
                >
                  <FieldInput data-testid='input-email' placeholder="Digite o email" />
                </Form.Item>
              </Label>
            </Col>
            <Col span={6}>
              <Label>
                Telefone
                <Form.Item
                  validateDebounce={500}
                  name="telefone"
                  rules={[
                    {
                      message: 'Número inválido',
                      min: 14
                    }
                  ]}
                >
                  <FieldInput.Phone
                    data-testid='input-phone'
                    name='input-phone'
                    style={{ width: '100%' }}
                    placeholder="Digite seu telefone"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={6}>
              <Label>
                Telefon secundário
                <Form.Item
                  validateDebounce={500}
                  name="telefoneSecundario"
                  rules={[
                    {
                      message: 'Número inválido',
                      min: 14
                    }
                  ]}
                >
                  <FieldInput.Phone
                    style={{ width: '100%' }}
                    data-testid='input-telefone-secundario'
                    name='input-telefone-secundario'
                    placeholder="Digite o telefone"
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Text
            size="h6"
            emphasis
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            Endereço do escritório
          </Text>
          <Row gutter={12} style={{ marginBottom: '30px' }}>
            <Col span={6}>
              <Label>
                CEP
                <Form.Item name={['endereco', 'cep']}>
                  <FieldInput.MASK
                    mask="99999-999"
                    onBlur={(event) => {
                      if (event?.target?.value.length >= 9) {
                        void fetchAddress(event.target.value)
                      }
                    }}
                    placeholder="00000-000"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={10}>
              <Label>
                Rua/Avenida
                <Form.Item name={['endereco', 'endereco']}>
                  <FieldInput
                    data-testid='input-rua'
                    placeholder="Digite o nome da rua ou avenida"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col span={4}>
              <Label>
                Número
                <Form.Item name={['endereco', 'numero']}>
                  <FieldInput data-testid='input-numero' placeholder="Digite o número" />
                </Form.Item>
              </Label>
            </Col>
            <Col span={4}>
              <Label>
                Complemento
                <Form.Item name={['endereco', 'complemento']}>
                  <FieldInput data-testid='input-complemento' placeholder="apt/sala/bloco" />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={10}>
              <Label>
                Bairro
                <Form.Item name={['endereco', 'bairro']}>
                  <FieldInput data-testid='input-bairro' placeholder="Digite o bairro" />
                </Form.Item>
              </Label>
            </Col>
            <Col span={10}>
              <Label>
                Cidade
                <Form.Item name={['endereco', 'cidade']}>
                  <FieldInput data-testid='input-cidade' placeholder="Digite a cidade" />
                </Form.Item>
              </Label>
            </Col>
            <Col span={4}>
              <Label>
                Estado
                <Form.Item name={['endereco', 'uf']}>
                  <Select
                    data-testid='input-estado'
                    placeholder="Selecione"
                    options={estados}
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col sm={0} md={0} lg={0} xl={6}>
        <Card data-testid='card-dicas-cadastro' aria-label=''>
          <Text style={{ marginBottom: '20px' }} emphasis size="h6">
            Por que é importante manter o cadastro completo e atualizado?
          </Text>
          <Text
            style={{ marginBottom: '10px', color: theme.color.text.subtlest }}
          >
            Prev é a plataforma previdenciária mais moderna e mais segura do
            mercado!
          </Text>
          <Text
            style={{ marginBottom: '10px', color: theme.color.text.subtlest }}
          >
            Aqui seus dados são protegidos, além disso não usaremos seu email
            para enviar promoções, usaremos somente para envio dos emails
            transacionais e de atualizações da plataforma.
          </Text>
        </Card>
      </Col>
    </Row>
  )
}
