import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { LocalLoading } from './shared-components/LocalLoading';
import { useScrollEnd } from './shared-components/useScrollEnd';
import useWidth from './shared-components/useWidth';

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: (props: any) => <Root {...props} />,
  errorBoundary() {
    return <div className="module-load-error"></div>;
  },
});


// Função customizada para atualizar o contexto
export const updateContext = async (props: any) => {
  if (lifecycles.update) {
    lifecycles.update(props);
  } else {
    console.warn('O aplicativo não está montado, não foi possível atualizar o contexto.');
  }
};

export const { bootstrap, update, mount, unmount } = lifecycles;

export { LocalLoading, useWidth, useScrollEnd };
